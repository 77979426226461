import React from "react";
import {Col, Row} from "react-bootstrap"
import {CaretDownFill, CaretUpFill} from "react-bootstrap-icons"


const Cryptos = React.memo(props => {
    const currencyFormatter = () => {
        return new Intl.NumberFormat('en-us', {
            minimumFractionDigits: props.minRound,
            maximumFractionDigits: props.maxRound,
        })
    }

    const titleStyle = {
        fontSize: "13px"
    }


    return (
        <>
            {isNaN(props.price.regularMarketPrice) === true
                ? <div></div>
                : <div className="col-md-12 pl-0 pr-0" style={titleStyle}>
                    <Col md={12}>
                        <Row>
                            <Col xs={6} className="text-left">
                                <strong>
                                    {props.price.regularMarketPrice < props.price.chartPreviousClose
                                        ? <CaretDownFill color="red" className="align-top mt-1 mr-2"/>
                                        : <CaretUpFill color="green" className="align-top mt-1 mr-2"/>
                                    }
                                    {props.title}
                                </strong>
                            </Col>
                            <Col xs={6} className="text-right">
                                {props.price.regularMarketPrice < props.price.chartPreviousClose
                                    ? <span className="text-danger">
                                        {currencyFormatter().format(props.price.regularMarketPrice - props.price.chartPreviousClose)}
                                    </span>
                                    : <span className="text-success">
                                        +{currencyFormatter().format(props.price.regularMarketPrice - props.price.chartPreviousClose)}
                                    </span>
                                }
                                &nbsp;{" | "}&nbsp;
                                {props.price.regularMarketPrice < props.price.chartPreviousClose
                                    ? <span className="text-danger">
                                        {(((props.price.regularMarketPrice - props.price.chartPreviousClose) / props.price.chartPreviousClose) * 100).toFixed(2)}{"%"}
                                    </span>
                                    : <span className="text-success">
                                        {(((props.price.regularMarketPrice - props.price.chartPreviousClose) / props.price.chartPreviousClose) * 100).toFixed(2)}{"%"}
                                    </span>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className="mt-1">
                        <Row>
                            <Col xs={3} className="text-left">Level</Col>
                            <Col xs={9} className="text-right">
                                {props.price && currencyFormatter().format(props.price.regularMarketPrice)}
                            </Col>
                        </Row>
                    </Col>
                </div>

            }
        </>
    )
})

export default Cryptos