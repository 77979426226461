import React from "react";
import { Badge } from "react-bootstrap";

const MarketIndicator = React.memo(props => {
        return (
            <>
                {props.marketState === "REGULAR"
                    ? <div><Badge pill variant="success">OPEN</Badge></div>
                    : props.marketState === "POST"
                    ? <div><Badge pill variant="primary">AFTER HOURS</Badge></div>
                    : props.marketState === "PRE"
                    ? <div><Badge pill variant="info">PRE-MARKET</Badge></div>
                    : <div><Badge pill variant="dark">CLOSED</Badge></div>
                }
            </>
        )
})

export default MarketIndicator
