import React, { useEffect } from "react";



const SymbolChart = (props) => {
    useEffect(() => {
        querySymbolHistory(props.sym)
    }, [props.sym])

    const querySymbolHistory = async (sym) => {
        if (sym !== "" || sym !== undefined) {
            console.log(sym)
        }
    }

    return (
        <>
        </>
    )
}

export default SymbolChart