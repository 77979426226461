// noinspection JSCheckFunctionSignatures

import React, { useEffect, useReducer } from "react";
import {Row, Col} from "react-bootstrap"
import { useAsync } from "react-async";
//import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

// Imported Components
import Futures from "./FuturesComponent";
import Cryptos from "./CryptosComponent";
import LoadingIndicator from "../../UI/loadingIndicator";
import MarketIndicator from "./MarketIndicator";

// Imported Styles
import "../../UI/loadingIndicator.css"


const httpReducer = (currentHttpState, action) => {
    switch (action.type) {
        case "SEND":
            return {loading: true, error: null}
        case "RESPONSE":
            return {...currentHttpState, loading: false}
        case "SET":
            return action.data
        default:
            throw new Error("http reducer error")
    }
}


const fetchMarketState = async () => {
    let url = 'https://13lcwigb4f.execute-api.us-east-1.amazonaws.com/prod/api/equities/quote2'
    let res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({sym: "ES"})
    })
    return res.json()
}


const HomeComponent = React.memo(() => {
    const [futuresHttpState, dispatchFuturesHttp] = useReducer(httpReducer, {loading: false, error: null})
    const [cryptosHttpState, dispatchCryptosHttp] = useReducer(httpReducer, {loading: false, error: null})
    const [treasuriesHttpsState, dispatchTreasuriesHttp] = useReducer(httpReducer, {loading: false, error: null})

    const stockTitles = ["DOW Jones Futures", "S&P Futures", "NASDAQ Futures", "Russel 2000"]
    const [spFutures, setSpFutures] = useReducer(httpReducer, "")
    const [dowFutures, setDowFutures] = useReducer(httpReducer, "")
    const [nasdaqFutures, setNasdaqFutures] = useReducer(httpReducer, "")
    const [russelFutures, setRusselFutures] = useReducer(httpReducer, "")

    const cryptoTitles = ["Bitcoin USD", "Ethereum USD", "EthereumClassic USD"]
    const [btc, setBtc] = useReducer(httpReducer, "")
    const [etc, setEtc] = useReducer(httpReducer, "")
    const [eth, setEth] = useReducer(httpReducer, "")

    const treasuryTitles = ["5 Year Bond", "10 Year Bond", "30 Year Bond"]
    const [fiveYear, setFiveYear] = useReducer(httpReducer, "")
    const [tenYear, setTenYear] = useReducer(httpReducer, "")
    const [thirtyYear, setThirtyYear] = useReducer(httpReducer, "")

    const {data, error, isPending} = useAsync({promiseFn: fetchMarketState})

    useEffect(() => {
        document.title = "Home"
        fetchFutures()
        fetchCryptos()
        fetchTreasuries()
        fetchMarketState()

    }, [])

    const fetchFutures = () => {
        dispatchFuturesHttp({type: "SEND"})
        let apiURI = "https://13lcwigb4f.execute-api.us-east-1.amazonaws.com/prod/api/futures"
        fetch(apiURI, {
            method: "GET"
        }).then(response => {
            return response.json()
        }).then(response => {
            setSpFutures({type: "SET", data: response[0][0].meta})
            setDowFutures({type: "SET", data: response[1][0].meta})
            setNasdaqFutures({type: "SET", data: response[2][0].meta})
            setRusselFutures({type: "SET", data: response[3][0].meta})
        }).then(() => {
            dispatchFuturesHttp({type: "RESPONSE"})
        }).catch(reason => {
            console.log(reason)
        })
    }

    const fetchCryptos = async () => {
        dispatchCryptosHttp({type: "SEND"})
        let apiURI = "https://13lcwigb4f.execute-api.us-east-1.amazonaws.com/prod/api/equities/qoute"
        let symbols = ["BTC-USD", "ETH-USD", "ETC-USD"]
        let result = symbols.map(function(sym) {
            return fetch(apiURI, {
                method: "POST",
                body: JSON.stringify({sym: sym, range: "2d", interval: "1d"})
            }).then(response => {
                return response.json()
            }).then(response => {
                return response[0].meta
            })
        })
        let cryptos = await Promise.all(result)
            .finally(() => dispatchCryptosHttp({type: "RESPONSE"}))
        cryptos.forEach(crypto => {
            switch (crypto.symbol) {
                case "BTC-USD":
                    setBtc({type: "SET", data: crypto})
                    break
                case "ETH-USD":
                    setEth({type: "SET", data: crypto})
                    break
                case "ETC-USD":
                    setEtc({type: "SET", data: crypto})
                    break
                default:
                    throw new Error("Error fetching cryptos prices")
            }
        })
    }

    const fetchTreasuries = async () => {
        dispatchTreasuriesHttp({type: "SEND"})
        let apiURI = "https://13lcwigb4f.execute-api.us-east-1.amazonaws.com/prod/api/equities/qoute"
        let symbols = ["%5EFVX", "%5ETNX", "%5ETYX"]
        let result = symbols.map(function(sym) {
            return fetch(apiURI, {
                method: "POST",
                body: JSON.stringify({sym: sym, range: "2d", interval: "1d"})
            }).then(response => {
                return response.json()
            }).then(response => {
                return response[0].meta
            })
        })
        let treasuries = await Promise.all(result)
            .finally(() => dispatchTreasuriesHttp({type: "RESPONSE"}))
        treasuries.forEach(treasury => {
            switch (treasury.symbol) {
                case "^FVX":
                    setFiveYear({type: "SET", data: treasury})
                    break
                case "^TNX":
                    setTenYear({type: "SET", data: treasury})
                    break
                case "^TYX":
                    setThirtyYear({type: "SET", data: treasury})
                    break
                default:
                    throw new Error("Error fetching treasury prices")
            }
        })
    }

    return (
        <div className="mt-5 pt-2 pb-5">
            {
                futuresHttpState.loading === false
                    ?
                    <Row className="mt-3">
                        <Col sm={6}>
                            <Row className="mb-0 pb-0">
                                <Col xs={8}>
                                    <h5>US STOCK MARKET</h5>
                                </Col>
                                <Col xs={4} className="text-right pl-0">
                                    {
                                        (isPending)
                                        ? <>Loading...</>
                                        : (error)
                                        ? <>{`Something went wrong: ${error.message}`}</>
                                        : (data)
                                        ? <MarketIndicator
                                                marketState={data.quoteResponse.result[0].marketState}
                                            />
                                        : null
                                    }
                                </Col>
                            </Row>

                            <hr/>
                            {[dowFutures, spFutures, nasdaqFutures, russelFutures].map((f, i) => (
                                <Row className="mb-3" key={i}>
                                    <Futures
                                        title={stockTitles[i]}
                                        price={f}
                                    />
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    : <Row>
                        <LoadingIndicator/>
                    </Row>
            }
            {
                cryptosHttpState.loading === false
                    ?
                    <Row className="mt-3">
                        <Col sm={6}>
                            <h5>CRYPTO CURRENCIES</h5>
                            <hr/>
                            {[btc, eth, etc].map((c, i) =>(
                                <Row className="mb-3" key={i}>
                                    <Cryptos
                                        title={cryptoTitles[i]}
                                        price={c}
                                        minRound={2}
                                        maxRound={2}
                                    />
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    : <Row className="mt-5">
                        <LoadingIndicator/>
                    </Row>
            }
            {
                treasuriesHttpsState.loading === false
                ?
                    <Row className="mt-3">
                        <Col sm={6}>
                            <h5>US TREASURIES</h5>
                            <hr/>
                            {[fiveYear, tenYear, thirtyYear].map((t, i) => (
                                <Row className="mb-3" key={i}>
                                    <Cryptos
                                        title={treasuryTitles[i]}
                                        price={t}
                                        minRound={3}
                                        maxRound={3}
                                    />
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    : <Row className="mt-5">
                        <LoadingIndicator/>
                    </Row>
            }
        </div>
    )
})

export default HomeComponent