import React from 'react';


const LoadingIndicator = () => (
    <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
    </div>

);

export default LoadingIndicator;