import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { PlusCircleDotted } from "react-bootstrap-icons";


// imported components


const SymbolTitle = React.memo(props => {
    return (
        <div className="mt-3">
            <Row>
                <Col xs={8}>  {/* Mobile View */}
                    {props.symbolTitle} ({props.sym})
                </Col>
                <Col xs={4}>
                    <Button variant="outline-primary" size="sm" className="font-weight-light btn-sm rounded-pill" style={{fontSize: "10px"}}>Watchlist <PlusCircleDotted/></Button>
                </Col>
            </Row>
        </div>
    )
})

export default SymbolTitle