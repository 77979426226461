import React from "react";
import { Row, Col, Table, Card } from "react-bootstrap";
import GaugeChart from 'react-gauge-chart'


const SupportLevels = React.memo(props => {
    const sectionTitleStyle = {
        fontSize: "14px"
    }
    const statFont = {
        fontSize: "10px"
    }

    const currencyFormatter = () => {
        return new Intl.NumberFormat('en-us', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }

    const analystBuyRating = rating => {
        let ratingsArray = rating.split('-')
        ratingsArray = ratingsArray.map(el => el.trim())
        ratingsArray[0] = parseFloat(ratingsArray[0])
        return ratingsArray
    }

    return (
        <>
            <Card className="pt-2 pb-2 pl-2 pr-2 mt-2 shadow rounded-sm border-0">
                <Row>
                    <Col xs={6}>
                        <strong className="pl-1" style={sectionTitleStyle}>Key Support Levels</strong>
                    </Col>
                    {
                        (props.priceEpsCurrentYear !== undefined || props.epsCurrentYear !== undefined || props.epsTrailingTwelveMonths !== undefined)
                        ? <Col xs={6}>
                                <strong className="pl-1" style={sectionTitleStyle}>Valuation Stats</strong>
                            </Col>
                        : <></>
                    }
                </Row>

                <Row>
                    <Col xs={6}>
                        <Table responsive="sm" size="sm" borderless>
                            <tbody>
                            <tr>
                                <td style={statFont}><strong>50d Avg</strong></td>
                                <td style={statFont}>{currencyFormatter().format(props.fiftyDayAverage)}</td>
                            </tr>
                            <tr>
                                <td style={statFont}><strong>52w Low</strong></td>
                                <td style={statFont}>{currencyFormatter().format(props.fiftyTwoWeekLow)}</td>
                            </tr>
                            <tr>
                                <td style={statFont}><strong>52w High</strong></td>
                                <td style={statFont}>{currencyFormatter().format(props.fiftyTwoWeekHigh)}</td>
                            </tr>
                            <tr>
                                <td style={statFont}><strong>200d Avg</strong></td>
                                <td style={statFont}>{currencyFormatter().format(props.twoHundredDayAverage)}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={6}>
                        {
                            (props.priceEpsCurrentYear !== undefined || props.epsCurrentYear !== undefined || props.epsTrailingTwelveMonths !== undefined)
                            ? <Table borderless responsive="sm" size="sm">
                                    <tbody>
                                    {
                                        props.priceEpsCurrentYear >= 0
                                        ? <tr>
                                                <td style={statFont}><strong>P/E:</strong></td>
                                                <td style={statFont}>{currencyFormatter().format(props.priceEpsCurrentYear)}</td>
                                            </tr>
                                        : <></>
                                    }

                                    {
                                        props.epsCurrentYear !== undefined
                                        ? <tr>
                                                <td style={statFont}><strong>EPS (Current Year):</strong></td>
                                                <td style={statFont}>{currencyFormatter().format(props.epsCurrentYear)}</td>
                                            </tr>
                                        : <></>
                                    }

                                    {
                                        props.epsTrailingTwelveMonths !== undefined
                                        ? <tr>
                                                <td style={statFont}><strong>EPS (TTM):</strong></td>
                                                <td style={statFont}>{currencyFormatter().format(props.epsTrailingTwelveMonths)}</td>
                                            </tr>
                                        : <></>
                                    }
                                    </tbody>
                                </Table>
                            : <></>
                        }
                    </Col>
                </Row>
                {
                    props.averageAnalystRating !== undefined
                        ? <>
                            <Row>
                                <Col xs={12} className="mb-0">
                                    <GaugeChart
                                        nrOfLevels={5}
                                        arcPadding={0.1}
                                        cornerRadius={3}
                                        hideText={true}
                                        percent={(analystBuyRating(props.averageAnalystRating)[0] / 5)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="text-center"><strong>Analyst Avg Rating:</strong> {(analystBuyRating(props.averageAnalystRating)[1])}</Col>
                            </Row>
                        </>
                        : <></>
                }
            </Card>
        </>
    )
})

export default SupportLevels