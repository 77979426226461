import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Authenticator } from "aws-amplify-react";
import NavbarComponent from "./UI/NavbarComponent";
import '@aws-amplify/ui/dist/style.css';


const AuthenticatorTheme = {
    button: { backgroundColor: "#0275d8", borderColor: "red", borderRadius: "5px" },
    signInButton: { backgroundColor: "blue", borderColor: "blue" }
}


function App() {
    const [authState, setAuthState] = useState('');
    useEffect(() => {
    }, [authState])

    const handleAuthState = (state) => {
        setAuthState(state)
    }

    return (
        <div className="vh-100">
            {
                authState !== 'signedIn'
                ?
                    <Row className="h-100 d-flex justify-content-center align-items-center">
                        <Col md={12} className="">
                            <Authenticator
                                onStateChange={e => handleAuthState(e)}
                                theme={AuthenticatorTheme}>
                            </Authenticator>
                        </Col>
                    </Row>

                :
                <Container>
                    <NavbarComponent/>
                </Container>
            }
        </div>
  );
}


export default App