import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";


const AddSymbolForm = React.memo(props => {
    const handleSubmit = event => {
        event.preventDefault();
    }

    return (
        <div className="mt-5 pt-5">
            <Row>
                {
                    isMobile
                    ? <Col xs={12}>  {/* Mobile View */}
                        <h5>Research Ticker Symbol</h5>
                        <hr/>
                      </Col>
                    : <Col md={6}>  {/* Browser View */}
                        <h5>Research Ticker Symbol</h5>
                        <hr/>
                      </Col>
                }
            </Row>
            <Row>
                {
                    isMobile
                    ? <Col xs={12}>  {/* Mobile View */}
                            <form onSubmit={handleSubmit}>
                                <Form.Group controlId="enterSymbol" className="custom-form-control" >
                                    <Form.Label>Ticker Symbol</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={props.symbolVal}
                                        onChange={event => props.inputChange(event)}
                                        placeholder="Enter equity ticker symbol"
                                    />
                                    <Form.Text muted>Example: AAPL</Form.Text>
                                </Form.Group>

                            </form>
                        </Col>
                    : <Col md={6}>  {/* Browser View */}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="enterSymbol" className="custom-form-control">
                                    <Form.Label>Ticker Symbol</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={props.symbolVal}
                                        onChange={event => props.inputChange(event)}
                                        placeholder="Enter equity ticker symbol"
                                    />
                                    <Form.Text muted>Example: AAPL</Form.Text>
                                </Form.Group>
                            </Form>
                        </Col>
                }
            </Row>
        </div>
    )
}, ((prevProps, nextProps) => {
    if (prevProps.symbolVal === nextProps.symbolVal) {
        return true
    }
    return false
}))

export default AddSymbolForm