import React, { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Switch from "react-bootstrap/Switch";
import { PersonFill, List, Search } from "react-bootstrap-icons"

// Components
import HomeComponent from "../Components/Home/HomeComponent";
import { Auth } from "aws-amplify"
import { currentUserInfo } from "../Auth/UserInfo";
import WatchList from "../Components/Research/WatchListComponent";
import Trading from "../Components/Trading/Trading";
import ScrollToTop from "./ScrollToTop";
import ResearchResults from "../Components/Research/ResearchSymbolResultsComponent";

const NavbarComponent = React.memo(() => {
    const [user, setUser] = useState("")
    const currentUserHandler = async () => {
        try {
            let currentUser = await currentUserInfo()
            setUser(currentUser)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        currentUserHandler()
    }, [])


    const handleSignOut = async () => {
        try {
            await Auth.signOut()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <Router>
                <Navbar fixed="top" bg="light" className="justify-content-front pt-2 shadow" >
                    <Nav.Item>
                        <Nav.Link href="/">Home</Nav.Link>
                    </Nav.Item>
                    <NavDropdown id="tradingDropdown" title={"Research"}>
                        <NavDropdown.Item eventKey="/watchlist-add" href="/watchlist-add"><Search/> Search Symbol</NavDropdown.Item>
                        <NavDropdown.Item eventKey="/watchlist" href="/watchlist"><List className="mb-1"/> View Watchlist</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item>
                        <Nav.Link eventKey="/trading" href="/trading">Trading</Nav.Link>
                    </Nav.Item>
                    <NavDropdown title={<PersonFill/>} className="dropdown-menu-right" alignRight={true}>
                        <NavDropdown.Item>
                            <p className="font-weight-light mb-0 mt-0">{user !== "" ? user.username : ""}</p>
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item onClick={handleSignOut}>Sign Out</NavDropdown.Item>
                    </NavDropdown>
                </Navbar>
                <ScrollToTop>
                    <Switch className="p-0">
                        <Route exact path="/" component={HomeComponent} />
                        <Route path="/watchlist" component={WatchList} />
                        <Route path="/watchlist-add" component={ResearchResults} />
                        <Route path="/trading" component={Trading} />
                    </Switch>
                </ScrollToTop>
            </Router>
        </>
    )
})

export default NavbarComponent