import React, { useEffect, useRef, useReducer } from "react";

// Component imports
import AddSymbolForm from "./ResearchSymbolFormComponent";
import SymbolTitle from "./SymbolTitleComponent";
import SupportLevels from "./SymbolStats/SupportLevelsComponent";
import SymbolChart from "./SymbolStats/SymbolChartComponent";
import SymbolPrice from "./SymbolStats/SymbolPriceComponent";


const symbolReducer = (currentSymbol, action) => {
    switch (action.type) {
        case "SET":
            return action.data
        case "UPDATE":
            return action.data
        default:
            throw new Error('symbolReducer error')
    }
}

const ResearchResults = React.memo(() => {
    const intervalRef = useRef()
    const [symbol, dispatch] = useReducer(symbolReducer, '')
    const [symbolData, dispatchSymbolData] = useReducer(symbolReducer, null)

    useEffect(() => { // initial rendering of symbol data
        if (symbol !== "") {
            const handleSubmit = setTimeout(() => {
                querySymbolAPI(symbol)
            }, 500)
            return () => clearTimeout(handleSubmit)

        } else {
            dispatchSymbolData({type: "UPDATE", data: null})
        }
    }, [symbol])

    useEffect(() => {
        const timer = setInterval(() => {
            if (symbol !== "") {
                querySymbolAPI(symbol)
            }
        }, 8000)
        intervalRef.current = timer
        return () => {
            clearInterval(intervalRef.current)
        }
    })

    const querySymbolAPI = async (sym) => {
        let url = 'https://13lcwigb4f.execute-api.us-east-1.amazonaws.com/prod/api/equities/quote2'

        let priceData = await fetch(url, {
            method: "POST",
            body: JSON.stringify({sym: sym})
        })
        priceData = await priceData.json()
        if (priceData.quoteResponse.result.length > 0) {
            console.log(priceData)
            dispatchSymbolData({type: "UPDATE", data: priceData.quoteResponse.result[0]})
        } else {
            dispatchSymbolData({type: "UPDATE", data: null})
        }
    }

    const handleInputChange = (event) => {
        let sym = event.target.value.trim().toUpperCase()
        dispatch({type: "SET", data: sym})
    }

    return (
        <>
            <AddSymbolForm
                inputChange={handleInputChange}
                symbolVal={symbol}
            />

            {
                symbolData !== null && symbolData.longName !== undefined
                    ? <>
                        <SymbolTitle
                            symbolTitle={symbolData.longName}
                            sym={symbolData.symbol}
                        />
                        <SymbolPrice
                            regularMarketPrice={symbolData.regularMarketPrice}
                            regularMarketChange={symbolData.regularMarketChange}
                            regularMarketChangePercent={symbolData.regularMarketChangePercent}
                            postMarketPrice={symbolData.postMarketPrice}
                            postMarketChange={symbolData.postMarketChange}
                            postMarketChangePercent={symbolData.postMarketChangePercent}

                            preMarketPrice={symbolData.preMarketPrice}
                            preMarketChange={symbolData.preMarketChange}
                            preMarketChangePercent={symbolData.preMarketChangePercent}
                        />
                        <SupportLevels
                            fiftyDayAverage={symbolData.fiftyDayAverage}
                            fiftyTwoWeekLow={symbolData.fiftyTwoWeekLow}
                            fiftyTwoWeekHigh={symbolData.fiftyTwoWeekHigh}
                            twoHundredDayAverage={symbolData.twoHundredDayAverage}
                            priceEpsCurrentYear={symbolData.priceEpsCurrentYear}
                            epsCurrentYear={symbolData.epsCurrentYear}
                            epsTrailingTwelveMonths={symbolData.epsTrailingTwelveMonths}
                            averageAnalystRating={symbolData.averageAnalystRating}
                        />
                        <SymbolChart
                            sym={symbolData.symbol}
                        />
                    </>
                    : <div></div>
            }

        </>
    )
})

export default ResearchResults