import React from "react";
import { Row, Col } from "react-bootstrap"


const SymbolPrice = React.memo(props => {

    const currencyFormatter = () => {
        return new Intl.NumberFormat('en-us', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }
    return (
        <>
            <Row className="align-items-baseline">
                <Col xs={4}>
                    <Row className="pb-0">
                        <Col xs={12}>
                            <h3 className="mb-0">{currencyFormatter().format(props.regularMarketPrice)}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="font-weight-light" style={{fontSize: "9px"}}>
                            Regular Market Price
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} className="align-text-bottom">
                    {
                        props.regularMarketChange >= 0
                        ? <h5 className="text-success">+{currencyFormatter().format(props.regularMarketChange)} (+{currencyFormatter().format(props.regularMarketChangePercent)}%)</h5>
                        : <h5 className="text-danger">{currencyFormatter().format(props.regularMarketChange)} ({currencyFormatter().format(props.regularMarketChangePercent)}%)</h5>
                    }
                </Col>
            </Row>
            {
                props.postMarketPrice !== undefined
                ? <Row className="align-items-baseline mt-2">
                        <Col xs={3}>
                            <Row className="pb-0">
                                <Col xs={12}>
                                    <h5 className="mb-0">{currencyFormatter().format(props.postMarketPrice)}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="font-weight-light" style={{fontSize: "9px"}}>
                                    After Hours
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={5} className="align-text-bottom">
                            {
                                props.postMarketChange >= 0
                                    ? <h6 className="text-success">+{currencyFormatter().format(props.postMarketChange)} (+{currencyFormatter().format(props.postMarketChangePercent)}%)</h6>
                                    : <h6 className="text-danger">{currencyFormatter().format(props.postMarketChange)} ({currencyFormatter().format(props.postMarketChangePercent)}%)</h6>
                            }
                        </Col>
                    </Row>
                : props.preMarketPrice !== undefined
                ? <Row className="align-items-baseline mt-2">
                        <Col xs={3}>
                            <Row className="pb-0">
                                <Col xs={12}>
                                    <h5 className="mb-0">{currencyFormatter().format(props.preMarketPrice)}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="font-weight-light" style={{fontSize: "9px"}}>
                                    Pre Market
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={5} className="align-text-bottom">
                            {
                                props.preMarketChange >= 0
                                    ? <h6 className="text-success">+{currencyFormatter().format(props.preMarketChange)} (+{currencyFormatter().format(props.preMarketChangePercent)}%)</h6>
                                    : <h6 className="text-danger">{currencyFormatter().format(props.preMarketChange)} ({currencyFormatter().format(props.preMarketChangePercent)}%)</h6>
                            }
                        </Col>
                    </Row>
                : <></>
            }
        </>
    )
})

export default SymbolPrice