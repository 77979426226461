import React from "react";


const Trading = () => {
    return (
        <div className="mt-5 pt-5">
            <strong>Coming soon... </strong>
        </div>
    )
}

export default Trading