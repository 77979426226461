/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:68a816af-ba35-4991-9d34-d9562100d3d5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_q5TGRZL4P",
    "aws_user_pools_web_client_id": "2peqsessk2p9u7o9av8jbr8h02",
    "oauth": {}
};


export default awsmobile;
